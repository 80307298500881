
#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  font-family: "Titillium Web", sans-serif;
}
